export default function useCornersInfo() {
  return {
    nv: {
      id: "nv",
      name: "Familjen Helsingborg",
      area: "nordvästra Skåne",
      postType: "educationnv",
      uri: "/nv",
      searchUri: "/nv/sok",
      theme: "nv",
      logo: "icon-logo-familjen-helsingborg.svg",
      mapIcon: "icon-corner-familjen-helsingborg.svg",
      municipalities: [
        "Bjuv",
        "Båstad",
        "Helsingborg",
        "Höganäs",
        "Klippan",
        "Landskrona",
        "Perstorp",
        "Svalöv",
        "Åstorp",
        "Ängelholm",
        "Örkelljunga",
      ],
    },
    no: {
      id: "no",
      name: "Skåne Nordost",
      area: "nordöstra Skåne",
      postType: "educationno",
      uri: "/no",
      searchUri: "/no/sok",
      theme: "no",
      logo: "icon-logo-skane-nordost.svg",
      mapIcon: "icon-corner-skane-nordost.svg",
      municipalities: [
        "Bromölla",
        "Hässleholm",
        "Kristianstad",
        "Osby",
        "Östra Göinge",
      ],
    },
    sv: {
      id: "sv",
      name: "Skåne Sydväst",
      area: "sydvästra Skåne",
      postType: "educationsv",
      uri: "/sv",
      searchUri: "/sv/sok",
      theme: "sv",
      logo: "icon-logo-skane-sydvast.svg",
      mapIcon: "icon-corner-skane-sydvast.svg",
      municipalities: [
        "Burlöv",
        "Eslöv",
        "Hörby",
        "Höör",
        "Kävlinge",
        "Lomma",
        "Lund",
        "Malmö",
        "Skurup",
        "Staffanstorp",
        "Svedala",
        "Trelleborg",
        "Vellinge",
      ],
    },
    so: {
      id: "so",
      name: "Skåne Sydost",
      area: "sydöstra Skåne",
      postType: "educationso",
      uri: "/so",
      searchUri: "/so/sok",
      theme: "so",
      logo: "icon-logo-skane-sydost.svg",
      mapIcon: "icon-corner-skane-sydost.svg",
      municipalities: ["Simrishamn", "Sjöbo", "Tomelilla", "Ystad"],
    },
  };
}
