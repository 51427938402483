import context from "@whitespace/gatsby-plugin-search/src/backend/context";
import useMiniSearch from "@whitespace/gatsby-plugin-search/src/backend/minisearch/useMiniSearch";
import useSearchDocuments from "@whitespace/gatsby-plugin-search/src/backend/minisearch/useSearchDocuments";
import { useSearchParams } from "@whitespace/gatsby-plugin-search/src/hooks";
import useAsync from "@whitespace/gatsby-plugin-search/src/hooks/useAsync";
import isEmptySearch from "@whitespace/gatsby-plugin-search/src/utils/isEmptySearch";
import { flattenDeep, isEmpty, negate } from "lodash-es";
import PropTypes from "prop-types";
import React, { useMemo } from "react";

MinisearchSearchBackendProvider.propTypes = {
  children: PropTypes.node,
  settings: PropTypes.shape({
    minisearch: PropTypes.object,
    attributesForFaceting: PropTypes.arrayOf(PropTypes.string),
  }),
  transformParams: PropTypes.func,
};

function extractKeys(objects) {
  return Object.keys(Object.assign({}, ...objects));
}

const isNotEmpty = negate(isEmpty);

const features = [
  // "facetCounts",
  "facets",
  "pagination",
  "query",
];

function normalizeDocuments(documents) {
  return flattenDeep(documents)
    .filter(isNotEmpty)
    .map((document) => ({
      ...document,
      text:
        (Array.isArray(document.text)
          ? flattenDeep(document.text).filter(isNotEmpty).join("\n")
          : document.text) || "",
    }));
}

export default function MinisearchSearchBackendProvider({
  children,
  settings: { miniSearch = {}, attributesForFaceting = [] } = {},
  transformParams = (params) => params,
}) {
  const { Provider } = context;
  let documents = normalizeDocuments(useSearchDocuments());

  let { params } = useSearchParams();

  let { page, hitsPerPage = 20, ...otherParams } = transformParams(params);

  /* First we sort by whether the course registration is open, pending, or closed (in that order)
     then we sort by title within each group */
  let sortOrder = "asc";
  let sortBy = [
    (obj) =>
      obj.openForRegistration
        ? 0
        : obj.educationInformation?.registrationPending
        ? 1
        : 2,
    "title",
  ];

  let request = {
    ...otherParams,
    sortBy,
    sortOrder,
    from: ((page || 1) - 1) * hitsPerPage,
    size: hitsPerPage,
  };

  const storeFields = extractKeys(documents);

  const { search } = useMiniSearch({
    documents,
    fields: ["label", "text"],
    storeFields,
    // extractField: (document, fieldName) => get(document, fieldName),
    attributesForFaceting,
    ...miniSearch,
  });

  const { result, error, isPending } = useAsync(
    () =>
      isEmptySearch(request)
        ? Promise.resolve({ isEmptySearch: true })
        : search(request),
    [JSON.stringify(request)],
  );

  const value = useMemo(
    () => ({
      ...result,
      documents,
      isPending,
      error,
      isError: !!error,
      page,
      hitsPerPage,
      totalPages: result && Math.ceil(result.totalHits / hitsPerPage),
      features,
    }),
    [JSON.stringify(result), page, hitsPerPage, isPending, error],
  );

  return (
    <Provider value={value}>
      {typeof children === "function" ? children(value) : children}
    </Provider>
  );
}
